import { useState } from 'react';
import axios from 'axios';

import { Layout, Menu, Dropdown, Modal, Table, Tabs } from 'antd';
import { Col, Row, Space, Upload, Card, Image, Typography, message, Spin, Button } from 'antd';
import { UploadOutlined, DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import type { UploadProps, MenuProps } from 'antd';
import type { RcFile, } from 'antd/es/upload/interface';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Result } from './components/result';

import './App.less';

const { Header, Footer, Content } = Layout;
const { Title, Paragraph } = Typography;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const api_url = process.env.REACT_APP_API_URL || '';
const prediction_endpoint = api_url + '/upload';
console.log(prediction_endpoint);

const imgs_demo = ['/imgs_demo/ID/benign_keratosis1.jpg', '/imgs_demo/OOD/other4.jpg', '/imgs_demo/OOD/bening_psorasis0.jpg',
'/imgs_demo/ID/actinic_keratosis2.jpg', '/imgs_demo/OOD/benign_skintag0.jpg', '/imgs_demo/OOD/other8.jpg',
'/imgs_demo/ID/melanocytic_nevus1.jpg', '/imgs_demo/OOD/other0.jpg', '/imgs_demo/ID/melanocytic_nevus0.jpg',
'/imgs_demo/OOD/other5.jpg', '/imgs_demo/OOD/other2.jpg', '/imgs_demo/OOD/benign_melanocytic_lentigo0.jpg',
'/imgs_demo/ID/actinic_keratosis1.jpg', '/imgs_demo/ID/actinic_keratosis0.jpg', '/imgs_demo/ID/basal_cell_carcinoma0.jpg',
'/imgs_demo/ID/basal_cell_carcinoma1.jpg', '/imgs_demo/ID/basal_cell_carcinoma3.jpg', '/imgs_demo/ID/basal_cell_carcinoma2.jpg',
'/imgs_demo/ID/squamous_cell_carcinoma0.jpg', '/imgs_demo/ID/melanoma1.jpg', '/imgs_demo/ID/squamous_cell_carcinoma1.jpg',
'/imgs_demo/OOD/other9.jpg', '/imgs_demo/ID/benign_keratosis0.jpg', '/imgs_demo/OOD/other6.jpg', '/imgs_demo/ID/benign_keratosis2.jpg',
'/imgs_demo/ID/melanoma2.jpg', '/imgs_demo/ID/melanoma0.jpg', '/imgs_demo/OOD/other7.jpg', '/imgs_demo/ID/actinic_keratosis3.jpg',
'/imgs_demo/OOD/other3.jpg', '/imgs_demo/OOD/other1.jpg'];

const items: MenuProps['items'] = [
  {
    label: "TPR90",
    key: "TPR90"
  },
  {
    label: "TPR95",
    key: "TPR95"
  }
];

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const getGT = (fn: string) => {
  console.log(fn.split('/'));
  let type = fn.split("/").at(-2);
  let name = fn.split("/").pop() || '.jpg';
  name = type + ' - ' + name.slice(0, -5).replace('_', ' ');
  return name;
}


function App() {

  const [imageUrl, setImageUrl] = useState<string>();
  const [imageInfo, setImageInfo] = useState<string>();
  const [imageGT, setImageGT] = useState<string>();
  const [working, setWorking] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [tpr, setTPR] = useState<string>('TPR90');

  const onTPRMenuClick: MenuProps['onClick'] = e => {
    setTPR(e.key);
  };

  const menuTPR = (
    <Menu onClick={onTPRMenuClick} selectedKeys={[tpr]} items={items}/>
  );


  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: prediction_endpoint,
    accept: "image/png, image/jpeg",
    maxCount: 1,
    customRequest: (options: any) => {
      const data= new FormData()
      data.append('file', options.file)
      const config= {
        "headers": {
          "content-type": 'multipart/form-data'   // ; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s
        }
      }
      axios.post(options.action, data, config).then((res: any) => {
        options.onSuccess(res.data, options.file)
      }).catch((err: Error) => {
        console.log(err)
      })
    },
    beforeUpload(file) {
      // console.log('Your upload file:', file);
      setWorking(true);
      getBase64(file as RcFile, url => {
        setImageUrl(url);
        setImageInfo(file.name + ' (' + file.type + ', ' + (file.size/1024/1024).toFixed(2) + 'MB)');
        setImageGT(getGT(file.name));
      });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        // console.log(info);
        setData(info.file.response);
        setWorking(false);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        console.log(info);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const callPrediction = (file: any) => {
    const data= new FormData()
    data.append('file', file)
    const config= {
      "headers": {
        "content-type": 'multipart/form-data'   // ; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s
      }
    }
    setWorking(true);
    axios.post(prediction_endpoint, data, config).then((res: any) => {
      setData(res.data);
      setWorking(false);
    }).catch((err: Error) => {
      console.log(err);
      message.error(`Failed to upload and get prediction`);
    })
  }

  const urlToFile = (url: any) => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      resolve(new File([blob], 'image.jpg', {type: blob.type}))
    }))

  const handleImageClick = (img: any) => {
    setWorking(true);
    setImageUrl(img);
    setImageInfo(img.split("/").pop());
    setImageGT(getGT(img));
    urlToFile(img).then( file => callPrediction(file));
  }

  const in_classes = ["benign:keratinocytic:actinic cheilitis","benign:keratinocytic:cutaneous horn","benign:keratinocytic:lichenoid","benign:keratinocytic:porokeratosis","benign:keratinocytic:seborrhoeic keratosis","benign:keratinocytic:solar lentigo","benign:keratinocytic:wart","benign:melanocytic:acral","benign:melanocytic:atypical","benign:melanocytic:benign nevus","benign:melanocytic:blue","benign:melanocytic:compound","benign:melanocytic:congenital","benign:melanocytic:dermal","benign:melanocytic:en cockarde","benign:melanocytic:halo","benign:melanocytic:ink spot lentigo","benign:melanocytic:lentiginous","benign:melanocytic:melanosis","benign:melanocytic:papillomatous","benign:melanocytic:ungual","benign:other:chrondrodermatitis","benign:other:comedone","benign:other:dermatofibroma","benign:other:excoriation","benign:other:fibrous papule face","benign:other:myxoid cyst","benign:other:nail dystrophy","benign:other:scar","benign:other:sebaceous hyperplasia","benign:vascular:angiokeratoma","benign:vascular:angioma","benign:vascular:haematoma","benign:vascular:other","benign:vascular:telangiectasia","malignant:bcc:basal cell carcinoma","malignant:bcc:pigmented basal cell carcinoma","malignant:bcc:recurrent basal cell carcinoma","malignant:bcc:superficial basal cell carcinoma","malignant:keratinocytic:actinic keratosis","malignant:melanoma:lentigo maligna","malignant:melanoma:melanoma","malignant:scc:keratoacanthoma","malignant:scc:scc in situ","malignant:scc:squamous cell carcinoma"];
  const ood_classes = ["benign:melanocytic:agminate","benign:melanocytic:ephilides","benign:melanocytic:involutingregressing","benign:melanocytic:irritated","benign:melanocytic:junctional","benign:melanocytic:lentigo","benign:melanocytic:reed nevus","benign:melanocytic:spitzoid","benign:melanocytic:traumatized","benign:other:accessory nipple","benign:other:dermatitis","benign:other:eczema","benign:other:epidermal cyst","benign:other:foliculitis","benign:other:granuloma annulare","benign:other:molluscum contagiosum","benign:other:psoriasis","benign:other:skin tag","malignant:bcc:nodular basal cell carcinoma","malignant:melanoma:nodular melanoma","Unknown skin conditions","Other open categories"];
  let dataSource:any =  [];
  for (var i=0; i < in_classes.length; i++) {
    dataSource.push({key: i, index: i+1, in_class: in_classes[i], ood_class: ood_classes[i] || ''})
  }

  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'In class',
      dataIndex: 'in_class',
      key: 'in_class',
    },
    {
      title: 'OOD class',
      dataIndex: 'ood_class',
      key: 'ood_class',
    },
  ]

  const info = () => {
    Modal.info({
      title: '',
      width: 800,
      content: (
        <Tabs defaultActiveKey="1">
          <TabPane tab="True Positive Rate (TPR)" key="1">
            <div>
              <div style={{paddingBottom:"15px"}}><Image style={{width:"100%"}} src='./AUROC-curve.jpg' preview={false}></Image></div>
              <p>The TPR here corresponds to all the "IN-classes" samples which will be considered as TP if the confidence prediction of that sample is higher than the threshold operating point.</p>
              <p>The FPR corresponds to all the "OOD" samples which will be considered as FP if the confidence of that sample is higher than the threshold operating point.</p>
              <p>Ideally, we would like to have a higher TPR with lower FPR. Please see the image below for the TPR/FPR trade-off.</p>
            </div>
          </TabPane>
          <TabPane tab="IN/OOD Classes" key="2">
            <div className="hoz-scroll">
              <Table dataSource={dataSource} columns={columns} />
            </div>
          </TabPane>
        </Tabs>
      ),
      onOk() {},
    });
  };

  /*
    <Col xs={24} lg={6}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon"><UploadOutlined /></p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
    </Col>
  */

  return (
    <>
      <Layout style={{height:"100vh"}}>

        <Header>
          <Row>
            <Title style={{color: "#eee", paddingTop: "5px"}}>Openset</Title>
            <Button type="primary" onClick={info} style={{ marginLeft: "auto", marginTop: "15px" }} icon={<InfoCircleOutlined/>}>Help</Button>
          </Row>
        </Header>

        <Content style={{padding: "10px"}}>
          <Row className="row" gutter={16}>

            <Col xs={24} >
              <Card>
                <ScrollMenu>
                  {imgs_demo.map( item => (
                    <img alt={item} key={item} src={item} className="img-item" onClick={() => handleImageClick(item)} />
                  ))}
                </ScrollMenu>
              </Card>

            </Col>
          </Row>

          { (!imageUrl && !working) &&
            <Row className="row" gutter={16}>
              <Col xs={24} style={{"textAlign": "center"}}>
                <Title level={3} style={{"color": "#ddd"}}>OpenSet demo</Title>
                <Title level={5} style={{"color": "#aaa"}}>Please choose an image to start!</Title>
              </Col>
            </Row>
          }

          <Row className="row" gutter={16}>
            <Col xs={24} lg={6}>
              { imageUrl &&
                <>
                  <Card>
                    <Space direction="vertical">
                      {imageUrl && <Image style={{width:"100%"}}  src={imageUrl} />}
                      {imageInfo && <div><b>Selected file:</b> {imageInfo}</div>}
                      {imageGT && <div><b>Ground truth:</b> {imageGT}</div>}
                    </Space>
                  </Card>

                  <div style={{paddingTop: "10px"}}>
                    <Card>
                      <Space direction="horizontal" style={{width: "100%"}}>
                        <b>Select TPR option</b>

                        <Dropdown overlay={menuTPR}>
                          <a onClick={ e => e.preventDefault() }>
                            <Space>
                              {tpr}
                              <DownOutlined />
                            </Space>
                          </a>
                        </Dropdown>
                      </Space>
                    </Card>
                  </div>
                </>
              }
            </Col>
            {working === true ? <Col xs={24} lg={18} className="spin-container"><Spin size="large" /></Col> :
              <>
                <Col xs={24} lg={9}>
                  <Result type="baseline" tpr={tpr} data={data}></Result>
                </Col>
                <Col xs={24} lg={9}>
                  <Result type="openset" tpr={tpr} data={data}></Result>
                </Col>
              </>
            }
          </Row>
        </Content>

        <Footer>
          © 2022 Copyright: Monash University
        </Footer>

      </Layout>
    </>
  )
}

export default App;
