import { useState, useEffect } from 'react';
import { Typography, Card, Slider, Space } from 'antd';

const { Title } = Typography;

const RANGE = 10000000
const thresholds: any = {
    'TPR90': {
        'baseline': [0.318, 0.415, 0.371629],
        'openset': [0.187, 0.307, 0.255745],
        'left': 'TPR95',
        'right': 'TPR85'
    },
    'TPR95': {
        'baseline': [0.13, 0.377, 0.3201805],
        'openset': [0.046, 0.263, 0.1907101],
        'left': 'TPR100',
        'right': 'TPR90'
    }
}

export function Result(props: any) {

    const [value, setValue] = useState(thresholds[props.tpr][props.type][2]);
    const [msg, setMsg] = useState<string>("");

    const isNotEmpty = (obj: any) => {
        return Object.keys(obj).length !== 0;
    }

    const getLevel2 = () => {
        // console.log(props.data);
        const preds = props.data[props.type]['pred_top5'];
        const probs = props.data[props.type]['prob_top5'];
        let items = [];
        for (var i=0; i < preds.length; i++) {
            const pred = preds[i];
            const prob = probs[i];
            const label = (i+1).toString() + '. ' + pred+ ' (' + prob.toFixed(4).toString() + ')';
            const percent = (prob*100).toString() + '%';
            items.push({label, percent});
        }
        return items;
    }

    const handeValueChange = () => {
        const probs = props.data[props.type]['prob_top5'];
        let ood_msg = ""
        if (probs[0] < value) {
            ood_msg = 'As the biggest confidence score (' +  probs[0].toFixed(4) + ') is less than the threshold (' + value.toString() + ') so model recommends OOD Image';
        }
        setMsg(ood_msg);
    }

    useEffect(() => {
        setValue(thresholds[props.tpr][props.type][2]);
    }, [props.tpr])

    useEffect(() => {
        if (isNotEmpty(props.data)) {
            handeValueChange();
        }
    }, [value])



    return (
        <>
            { isNotEmpty(props.data) &&
                <Card hoverable={true} style={{width:"100%"}}>
                    <Title level={2} style={{textAlign: "center", textTransform: "capitalize"}}>{props.type} ({props.tpr})</Title>

                    <Title level={5}>ID Category Top 5</Title>
                    <div className="bar-container">
                        {
                            getLevel2().map ( (item, ind) => (
                                <div key={ind} className="bar-grey">
                                    <div className="bar-blue" style={{width:item['percent']}}><span className="bar-text">{item['label']}</span></div>
                                </div>
                            ))
                        }
                    </div>

                    <div>
                        <Title level={5}>Current threshold value: {value}</Title>
                    </div>

                    <div style={{width: "100%"}}>
                        <span>{thresholds[props.tpr]['left']}</span>
                        <span style={{float: "right"}}>{thresholds[props.tpr]['right']}</span>
                    </div>

                    <div>
                        <Slider min={thresholds[props.tpr][props.type][0]} max={thresholds[props.tpr][props.type][1]} step={0.00001}
                                 onChange={setValue} value={value} />
                    </div>

                    <div>
                        <Title level={5}>{msg}</Title>
                    </div>

                </Card>
            }
        </>
    );
}